<script setup>
import { ref, reactive, onMounted } from 'vue'
import { ElMessage, ElNotification } from 'element-plus'
import { handleSDKErrorNotifi } from '@/utils/handleSomeData'
import { createImageCode, fetchAuthCode, registerUser,okregisterUser } from '@/api/register'
/* emits */
const emits = defineEmits(['changeToLogin'])
const registerFormEl = ref(null)
const resetPasswordFrom = ref({
    username: '',
    nickname: '',
    password: '',
    inviter_code: '',
    confirmPwd: '',
    avatarurl: '',
    avatar: '',
})
const upAvatarUrl = JSON.parse(window.sessionStorage.getItem('webimConfig'))
    .server.jk //图片上传地址

const validatePass2 = (rule, value, callback) => {
    if (value === '') {
        callback(new Error('请再次输入您密码！'))
    } else if (value !== resetPasswordFrom.value.password) {
        callback(new Error('两次输入的密码不一样！'))
    } else {
        callback()
    }
}

const rules = reactive({
    username: [
        {
            required: true,
            message: '请输入用户账号',
            trigger: ['change', 'blur']
        },
        {
            pattern: /^[a-z|0-9|_|-|.]{3,30}$/g,
            message: '请输入3~30位的小写字母或数字、“_”、“-”、“.”',
            trigger: ['change', 'blur']
        }
    ],
    nickname: [
        {
            required: true,
            message: '请输入用户昵称',
            trigger: ['change', 'blur']
        },
        {
            pattern: /^[\s\S]{2,20}$/g,
            message: '请输入2~20位的用户昵称',
            trigger: ['change', 'blur']
        }
    ],
    password: [
        { required: true, message: '请输入密码', trigger: 'blur' },
        {
            pattern: /^[\s\S]{5,30}$/g,
            message: '请输入5~30位的密码',
            trigger: ['change', 'blur']
        }
    ],
    confirmPwd: [
        {
            required: true,
            validator: validatePass2,
            trigger: ['blur', 'change']
        }
    ],
    inviter_code: [
        {
            required: Boolean(
                JSON.parse(window.sessionStorage.getItem('webimConfig')).configs
                    .is_inviter_reg
            ),
            message: '请输入邀请码',
            trigger: ['change', 'blur']
        }
    ]
})

const uploadSuccess = (res) => {
    if (res.code === 1) {
        resetPasswordFrom.value.avatarurl = res.data.fullurl
        resetPasswordFrom.value.avatar = res.data.url
        ElMessage({
            message: '头像上传成功',
            type: 'success',
            center: true,
            showClose: true
        })
    } else {
        ElMessage({
            message: '头像上传失败：' + res.msg,
            type: 'error',
            center: true,
            showClose: true
        })
    }
}
const uploadErr = (res) => {
    ElMessage({
        message: '头像上传失败：' + res.msg,
        type: 'error',
        center: true,
        showClose: true
    })
}





/* 发起注册 */
const buttonLoading = ref(false)
const resetPwdEl = ref()

const registerIM = async (formEl) => {
    if (!formEl) return
    if (resetPasswordFrom.value.avatarurl === '') {
        ElMessage({
            message: '请上传头像',
            type: 'error',
            center: true,
            showClose: true
        })
        return
    }
    await formEl.validate(async (valid) => {
        if (!valid) {
            buttonLoading.value = false
        }
        else  {
          
            const params = {
                username: resetPasswordFrom.value.username,
                nickname: resetPasswordFrom.value.nickname,
                password: resetPasswordFrom.value.password,
                inviter_code: resetPasswordFrom.value.inviter_code,
                avatar: resetPasswordFrom.value.avatar
            }
            try {
                await okregisterUser(params).then((res)=>{
                    if (res.code === 1) {
                        ElNotification({
                   
                    message: '注册成功！',
                    center: true,
                    type: 'success'
                      })
                      //通知改变为登陆模式
                  emits('changeToLogin')
                    }
                    else {
                        ElMessage({
                            message: '注册失败：' + res.msg,
                            type: 'error',
                            center: true,
                            showClose: true
                        })
                    }
                })
              
                
             
            } catch (error) {
                if (error.response.data) {
                    ElMessage({
                        message: '注册失败：' + error,
                        type: 'error',
                        center: true,
                        showClose: true
                    })
                    const { code, errorInfo } = error.response.data
                    handleSDKErrorNotifi(code, errorInfo)
                }
            }
        }
    })
}
</script>

<template>
    <el-form ref="resetPwdEl" :model="resetPasswordFrom" :rules="rules">
        <el-form-item prop="username" class="login_item" style="margin-top: 15px;">
            <template #label>头像</template>
            <el-upload class="upload-demo" drag :action="upAvatarUrl + '/api/common/upload'"
                accept="image/jpg, image/jpeg, image/png" :show-file-list="false" :on-success="uploadSuccess"
                :on-error="uploadErr">
                
                <el-avatar class="infor_avatar" :size="80" :src="resetPasswordFrom.avatarurl"
                    v-if="resetPasswordFrom.avatarurl"></el-avatar>
                <div v-if="!resetPasswordFrom.avatarurl">
                    <img :src="jiaIcon" class="imgs_wer" />
                </div>
              
            </el-upload>
        </el-form-item>
        <el-form-item prop="username" class="login_item">
            <template #label>
                <img style="width: 15px; height: 19px; margin-top: 4px" src="@/assets/login/userIcon.png" />
            </template>
            <el-input class="login_input_style" v-model="resetPasswordFrom.username" placeholder="请输入用户登陆账号" clearable
                autofocus />
        </el-form-item>
        <el-form-item prop="nickname" class="login_item">
            <template #label>
                <img style="width: 15px; height: 19px; margin-top: 4px" src="@/assets/login/userIcon.png" />
            </template>
            <el-input class="login_input_style" v-model="resetPasswordFrom.nickname" placeholder="请输入用户昵称" clearable
                autofocus />
        </el-form-item>
        <el-form-item prop="password" class="login_item">
            <template #label>
                <img style="width: 15px; height: 18px; margin-top: 5px" src="@/assets/login/pwdIcon@2x.png" />
            </template>
            <el-input class="login_input_style" v-model="resetPasswordFrom.password" placeholder="请输入密码" show-password
                clearable>
            </el-input>
        </el-form-item>
        <el-form-item prop="confirmPwd" class="login_item">
            <template #label>
                <img style="width: 15px; height: 18px; margin-top: 5px" src="@/assets/login/pwdIcon@2x.png" />
            </template>
            <el-input class="login_input_style" v-model="resetPasswordFrom.confirmPwd" placeholder="请确认密码" show-password
                clearable @keydown.enter="registerIM(resetPwdEl)">
            </el-input>
        </el-form-item>
        <el-form-item prop="inviter_code" class="login_item">
            <template #label>
                <img style="width: 19px; height: 16px; margin-top: 7px" src="@/assets/login/invitationIcon@2x.png" />
            </template>
            <el-input class="login_input_style" v-model="resetPasswordFrom.inviter_code" placeholder="请输入邀请码(选填)" clearable
                @keydown.enter="registerIM(resetPwdEl)">
            </el-input>
        </el-form-item>
        <el-form-item>
            <div class="function_button_box">
                <el-button class="haveValueBtn" @click="registerIM(resetPwdEl)" round :loading="buttonLoading"
                    size="large">注册</el-button>
            </div>
        </el-form-item>
    </el-form>
</template>

<style lang="scss" scoped>


.imgs_wer {
    display: inline-block;
    width: 30px;
    height: auto;
    margin-top: 26px;
}

.upload-demo {
    width: 80px;
    height: 80px;
    margin: 0 auto 20px auto;
    border: 1px dotted #cccccc;
    overflow: hidden;
    border-radius: 80px;
}

.upload-avatar {
    width: 100px;
    height: 100px;
    border: 1px solid #000;
}
.function_button_box {
    margin-top: 10px;
    width: 400px;

    button {
        margin: 10px;
        width: 380px;
        height: 50px;
        border-radius: 57px;
        background: linear-gradient(90deg, #04aef0 0%, #5a5dd0 100%);
        border: none;
        font-weight: 300;
        font-size: 17px;
        color: #f4f4f4;

        &:active {
            background: linear-gradient(90deg, #0b83b2 0%, #363df4 100%);
        }
    }
}

.login_input_style {
    margin: 3px 0;
    width: 400px;
    height: 50px;
    padding: 0 16px;
}

:deep(.el-input__inner) {
    padding: 0 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.75px;
    color: #3a3a3a;

    &::placeholder {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height */

        letter-spacing: 1.75px;
        text-transform: uppercase;

        color: #cccccc;
    }
}
:deep(.el-form-item__error) {
    margin-left: 16px;
}
:deep(.el-input__suffix-inner) {
    font-size: 20px;
    margin-right: 15px;
}
.login_text {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    text-align: right;

    .login_text_isuserid {
        display: inline-block;
        // width: 100px;
        color: #f9f9f9;
    }

    .login_text_tologin {
        margin-right: 20px;
        width: 80px;
        color: #05b5f1;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}

.auth_code {
    width: 80px;
    height: 40px;
}
</style>
