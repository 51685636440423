//聊天相关
import request from '@/utils/request'

// 单聊key
export function getSingleKey(params) {
    return request({
        url: '/api/chat/chatindex',
        method: 'post',
        data: params
    })
}

// 群聊key
export function getGroupKey(params) {
    return request({
        url: '/api/chat/groupindex',
        method: 'post',
        data: params
    })
}

// 获取用户IP
export function getUserIp(params) {
    return request({
        url: `/api/user/get_user_ip?username=${params}`,
        method: 'get'
    })
}

// 获取用户IP
export function getUserInfoData(params) {
    return request({
        url: `/api/user/getNicknameByUsername?username=${params}`,
        method: 'get'
    })
}

// 上传媒体资源
export function uploadSomething(params, uploadProgressEvent) {
    return request({
        url: '/api/common/upload',
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: uploadProgressEvent
    })
}
// 上传媒体资源
export function uploadSomethingg(params) {
    return request({
        url: '/api/common/upload',
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}
// 设置置顶
export function settop(params) {
    console.log('paramsparamsparamsparams', params)
    return request({
        url: '/api/chat/settop',
        method: 'post',
        data: params,
    })
}

// 置顶列表
export function toplist(params) {
    return request({
        url: '/api/chat/toplist',
        method: 'get'
    })
}