import CryptoJS from 'crypto-js';


export function aesEncrypt(content, key) {
  const keyStr = CryptoJS.enc.Utf8.parse(key);
  const iv = '';
  const encrypted = CryptoJS.AES.encrypt(content, keyStr, {
    iv,
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return '###' + encrypted.ciphertext.toString(CryptoJS.enc.Base64);
}


export function aesDecrypt(content, key) {
  const keyStr = CryptoJS.enc.Utf8.parse(key);
  const iv = '';
  const encrypted = CryptoJS.AES.decrypt(content, keyStr, {
    iv,
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return encrypted.toString(CryptoJS.enc.Utf8);
}
