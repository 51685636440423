import axios from 'axios'
//const defaultBaseUrl = '//a1.easemob.com'
//const defaultBaseUrl = '//40.65.180.126:47850'
//
let defaultBaseUrl
if (!window.sessionStorage.getItem('webimConfig')) {
    let configUrl
    axios.get('https://jdhkk.blob.core.windows.net/test/config.json').then((res) => {
        console.log('初始化配置URL', res.data.url)
        configUrl = res.data.url + '/api/common/init'
        window.sessionStorage.setItem(
            'webimConfig',
            JSON.stringify(res.data.url)
        )
        defaultBaseUrl = res.data.url
        // window.location.reload()/deim1/xintest
        axios
            .get(
                configUrl // H5动态地址
            )
            .then((result) => {
                console.log('初始化配置', result)
                if (result.status === 200) {
                    window.sessionStorage.setItem(
                        'webimConfig',
                        JSON.stringify(result.data.data)
                    )
                    defaultBaseUrl = result.data.data.server.jk
                    window.location.reload()
                }
            })
    })
} else {
    const webimConfig = window.sessionStorage.getItem('webimConfig')
    defaultBaseUrl = JSON.parse(webimConfig).server.jk
}
// create an axios instance

const userInfo = window.localStorage.getItem('EASEIM_loginUser')
const TOKEN = (userInfo && JSON.parse(userInfo)) || {}
//console.log('token'+TOKEN.webToken)

const service = axios.create({
    withCredentials: false,
    // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    //baseURL: `${window.location.protocol}${defaultBaseUrl}`,
    baseURL: defaultBaseUrl,
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 30000, // request timeout
    headers: { 'Content-Type': 'application/json',
                 token: TOKEN.webToken
                }
})
// request interceptor
service.interceptors.request.use(
    (config) => {
        // do something before request is sent
        return config
    },
    (error) => {
        // do something with request error

        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    (response) => {
        const res = response.data
        const code = response.status
        // if the custom code is not 20000, it is judged as an error.
        if (code >= 400) {
            return Promise.reject(new Error(res.desc || 'Error'))
        } else {
            return res
        }
    },
    (error) => {
        if (error.response) {
            const res = error.response.data // for debug
            if (error.response.status === 401 && res.code !== '001') {
            }
            if (error.response.status === 403) {
                res.desc = '您没有权限进行查询和操作!'
            }
            return Promise.reject(res.desc || error)
        }
        return Promise.reject(error)
    }
)

export default service
